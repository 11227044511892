import React from 'react';
import './index.scss';

const Dashboard = () => {
  return (
    <div className="dashboard-wrapper">
      <p>Error loading your details!!</p>
    </div>
  );
};

export default Dashboard;
